/* этот код был написан поздно ночью, не бейте за него палками*/

$(window).on('load', function () {
  /*меню */
  $('.header__burg').on('click', function () {
    if ($('.header__burg').hasClass("header__burg_opened")) {
      $('.header__burg').removeClass('header__burg_opened');
      $('.header__menu').slideUp(700);
      $('.header__burg-bar').css('background', '#e22a36');
    } else {
      $('.header__burg').addClass('header__burg_opened');
      $('.header__menu').slideDown(700);
      $('.header__burg-bar').css('background', '#979797');
    }
  })

  $('.slider').slick({
    nextArrow: document.querySelector('.slide'),
    prevArrow: '',
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });


  $('.download__writetous').magnificPopup();

  /*SCROLL */

  $('.slider-mouse').on('click', function (e) {
    e.preventDefault();

    var id = $(this).attr('href');
    var top = $(id).offset().top;

    $('body,html').animate({
      scrollTop: top
    }, 800);

  });


  $('.slide').click(aboutSlide);
  $('ul.slick-dots>li').click(aboutSlide);
  /* НЕ РАЗВОРАЧИВАТЬ!!!*/
  function aboutSlide() {
    var slickDots = $('ul.slick-dots>li');

    if (slickDots[0].classList.contains("slick-active")) {
      $('.slide-top__text>span.text1').text('Слайд 1');
      $('.slide-top__text>span.text2').text('Описание 1');
      $('.slide-bottom__count').text('01');
    }
    if (slickDots[1].classList.contains("slick-active")) {
      $('.slide-top__text>span.text1').text('Слайд 2');
      $('.slide-top__text>span.text2').text('Описание 2');
      $('.slide-bottom__count').text('02');
    }
    if (slickDots[2].classList.contains("slick-active")) {
      $('.slide-top__text>span.text1').text('Слайд 3');
      $('.slide-top__text>span.text2').text('Описание 3');
      $('.slide-bottom__count').text('03');
    }
    if (slickDots[3].classList.contains("slick-active")) {
      $('.slide-top__text>span.text1').text('Слайд 4');
      $('.slide-top__text>span.text2').text('Описание 4');
      $('.slide-bottom__count').text('04');
    }
  }
  ymaps.ready(init);

  function init() {
    var myMap = new ymaps.Map("map", {
      center: [45.06012740178365, 39.000312999999935],
      zoom: 16
    }, {
      suppressMapOpenBlock: true
    });
    myMap.behaviors.disable([
      'scrollZoom'
    ]);
    myMap.controls
      .remove('geolocationControl')
      .remove('trafficControl')
      .remove('rulerControl')
      .remove('searchControl')
      .remove('zoomControl')
      .remove('typeSelector')
      .remove('fullscreenControl')
  }

})